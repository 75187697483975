window.addEventListener("load", function () {

    // missing forEach on NodeList for IE11
    if (window.NodeList && !NodeList.prototype.forEach) {
        NodeList.prototype.forEach = Array.prototype.forEach;
    }

    function findAncestor(el, sel) {
        while ((el = el.parentElement) && !((el.matches || el.matchesSelector).call(el,sel)));
        return el;
    }

    const accordions = document.querySelectorAll('.accordion');

    accordions.forEach(function(accordion) {
        const items = accordion.querySelectorAll('.accordion__item');
        const questions = accordion.querySelectorAll('.accordion__button');
    
        questions.forEach(function(question) {
            question.addEventListener('click', function() {
                const thisItem = findAncestor(this, ".accordion__item");

                items.forEach(function(item) {
                    if (thisItem == item) {
                        thisItem.classList.toggle('accordion__item--show');
                        return;
                    }
        
                    item.classList.remove('accordion__item--show');
                });
            })
        });
    });
    
}, false);