import tippy from 'tippy.js';
import $ from 'jquery';
window.$ = window.jQuery = $;
import 'jquery-ui/ui/widgets/autocomplete.js';

window.addEventListener("load", function () {
   
   tippy('.tt', {
      content(reference) {
         const id = reference.getAttribute('data-template');
         const template = document.getElementById(id);
         return template.innerHTML;
      },
      trigger: 'click',
      allowHTML: true,
      maxWidth: 300,
      arrow: false,
      placement: 'top-start',
      interactive: true
   });   

}, false);

//js for hiding fields
$( "<p>Bitte geben Sie die benötigten Daten an, um an der Aktion teilzunehmen.</p>" ).prependTo( "#FormBuilder_anmeldung" );
$("#wrap_Inputfield_praegezeile").find(".InputfieldContent").find(".notes").addClass('tt');
$("#wrap_Inputfield_praegezeile").find(".InputfieldContent").find(".notes").attr('data-template', 'tt-pz');
$("#wrap_Inputfield_bank").find(".InputfieldContent").find(".notes").addClass('tt');
$("#wrap_Inputfield_bank").find(".InputfieldContent").find(".notes").attr('data-template', 'tt-blz');
$("#wrap_anmeldung_submit").before("<p>Eine Aktion der DZ BANK AG, dem Zentralinstitut der Volksbanken Raiffeisenbanken</p><p class='form-meta'>* Pflichtfeld</p>");

if($(".FormBuilderErrors .alert.alert-error.error").html() == ""){
   $(".FormBuilderErrors .alert.alert-error.error").html("Ihre Registrierung konnte leider nicht durchgeführt werden. Haben Sie alle Daten richtig eingegeben, so sind Sie gemäß <a href='https://apple-pay-gutschrift.de/site/templates/assets/pdf/teilnahmebedingungen.pdf' target='_blank' style='color: #a20038;text-decoration: underline;'>Teilnahmebedingungen</a> nicht zur Teilnahme berechtigt.")
}

//get all banks from processwire and put them in an autocompletion field
var availableTags = [];
$.ajax({
   type: "POST",
   url:  "/functions/getBlz.php",
   data: { type: "getBanks" },
   success: function(data){
      var numberOfElements = Object.keys(data).length;
      var bankname         = "";
      var blz              = "";
      
      for(let i = 0; i < numberOfElements; i++){
         blz      = data[i]['blz'];
         bankname = data[i]['bankname'];

         availableTags.push(bankname + " | " + blz);
      }

      $("#Inputfield_bank").autocomplete({
         html:true,
         source: availableTags
      });
          
   },
   error: function(jqXHR, textStatus, errorThrown){
      console.log(jqXHR);
      console.log(errorThrown);

   }
});